@import 'settings';
@import '~foundation-sites/scss/foundation';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC&family=Nunito+Sans&display=swap');

body {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 200;
}

button{
  color: $pink-link;
  padding: 10px 30px;
  font-size: 0.8em;
  font-weight: 600;
  background: transparent;
  border-radius: 1rem;
  border: 2px solid $pink-link;
  margin: 40px auto 0;
  cursor: pointer;
  opacity: 1;
}

.app {
  margin: 0;
}

::selection {
  background-color: #0bf;
  color: #fff;
}

.container {
  padding-right: 1.625rem;
  padding-left: 1.625rem;
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
}

a {
  color: $black;

  &:active {
    color: $black;
  }
  &:hover {
    color: $pink-link;
    transition: 0.7s;
    cursor:pointer;
  }
}

// HEADER
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;
  min-height: 10vh;
  font-size: 18px;
  
  svg {
    color: $black;
    font-size: 2rem;
    &:hover {
      color: $pink-link;
      transition: 0.7s;
      cursor:pointer;
    }
  }

  &__logo {
    font-size: 2rem;
    font-weight: 600;
  }

  &__logo-light {
    font-size: 2rem;
    font-weight: 400;
  }

  &__social {
    padding-left: 25px;
  }
}

.header-color {
  background-color: $white;
  border-bottom: 1px solid #f2f2f2;
}

//Products
.products {
  margin: 25px 0px 25px 0px;

  p {
    font-size: 1.2rem;
    line-height: 1.8;
  }
}

h1 {
  font-weight: 700;
  color: $black;
  font-size: 40px;
  line-height: 1.5;
  margin-bottom: 24px;
}

h2 {
  font-weight: 600;
  color: $black;
  font-size: 32px;
  line-height: 1.3;
  margin-bottom: 24px;
}

h3 {
  font-weight: 700;
  color: $black;
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1.5;
  margin: 0px 0px 25px 0px;
}

p {
  font-size: 20px;
  color: $dark-gray;
  line-height: 1.5;
  margin-top: 12px;
}

.stacks {
  font-size: 18px;
  font-weight: 700;
  color: $black;
  line-height: 1.5;
  margin-top: 12px;
}

.links {
  font-size: 18px;
  font-weight: 500;
  color: $black;
  line-height: 1.5;
  margin-top: 12px;
  a {
    color: $pink-link;

    &:hover {
      color: #1f3a93;
    }
  }
}

.images {
  width: auto;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.preview {
  padding: 20px;
}

.image-link {
  transform:scale(1, 1);
  transition: 1s;
  
  

  &:hover {
      transform:scale(0.90, 0.90);
      filter: grayscale(100%);
  }
}


.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.intro {
  margin: 50px 0 50px 0;

  p {
    font-size: 2rem;
    line-height:1.5;
    font-weight: 300;
    margin-bottom: 25px;
  }

  .codebox {
    background-color: #24252a;
    border-radius: 5px;
    padding: 30px 5% 30px 30px;

    p {
      font-size: 1.2rem;
      font-weight: 300;
      color: $white;
    }

    .pink {
      color: #e74292;
    }

    .grey {
      color: #777e8b;
    }

    .lightblue {
      color: #74b9ff;
    }
    
    .green {
      color: #badc57;
     }

     .lightblue {
      color: #74b9ff;
    }

    a {
      color: #0bf;
      text-decoration: underline;
    }

  }

}

.list-intro {
  font-size: 1.4rem;
  padding: 10px;

}

.link-header {
  margin-bottom: 10px;
  
  a {
    color: $pink-link;
    font-weight: 600;
    font-size: 1.2rem;
    text-transform: uppercase;
  }
}

.stack {
  margin-top: 2px;
  color: $dark-gray;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
}

.small-text {
  height: 90px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}


footer {
  background-color: $white;
  color: $dark-gray;
  min-height: 10vh;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
}



.skills {
  margin: 50px 0 50px 0;
  border: 1px solid #f2f2f2;
  padding: 25px;
  border-radius: 1rem;

  &__skill {
    padding: 10px 0 10px 0;
    font-weight: 600;
  }

  svg {
    font-size: 2rem;
    margin-bottom: 25px;
  }

}

.about {

  &__button {
    margin-top: 50px;
    border-radius: 0.4rem;
    background-color: $pink-link;
    padding: 15px;
  }


  p {
    font-size: 20px;
    color: $dark-gray;
    line-height: 1.5;
  }

  a {
    color: $pink-link;
    margin-top: 10px;
    font-weight: 600;
    font-size: 1.2rem;
    text-transform: uppercase;

    &:hover {
      color: #1f3a93;
    }
  }
}